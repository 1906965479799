import React from 'react';
import styled from 'styled-components';
import {IntrinsicElementProps} from "../../utils";
import {TwoToneText} from "../../components/TwoToneText";
import {TextLogo} from "../../components/TextLogo";
import {Media} from "../../constants";
import EarlyAccessBetaForm from "../../components/EarlyAccessBetaForm";
// @ts-ignore
import PhonesContextImage from "../../images/cover-context-image.png";


export type TheProductProps = {} & IntrinsicElementProps;

export function TheProduct({...props}: TheProductProps) {
    return <Container {...props}>
        <h2><TwoToneText w1={'The'} w2={'Product'}/></h2>
        <div className={'row'}>
            <div className={'A'}>
                <img className='productImage' src={PhonesContextImage}/>
            </div>
            <div className={'B'}>
                <div className={'blurb'}>
                    <p>Our goal is provide your ideal active support as part of your realtime internet experience.</p>
                    <p>The aim is to identify the exact moment when you start an addictive 'session' and proactively engage with a 'buddy' approach. At the very least, we can offer therapeutically-informed, non-coercive alternatives.</p>
                    <p>You can choose an alternative, or choose to continue with your porn session. Our goal is to be a buddy, not a 'hard' block. If you really want to watch porn, you'll work around such blockers eventually anyway.</p>
                    <p>Our priority is to be a therapeutically helpful ally. We are always on your side, working <em>with</em> you, not against you.</p>

                    <h3>How can I get it?</h3>
                    <p><TextLogo/> is currently an early-access beta app for Android. That means we are actively working on our first public release right now - due Summer/Autumn 2022.</p>
                    <p>If you are really keen to try it out and give feedback on how it works for you, sign up for access with the form below.</p>
                </div>
            </div>
        </div>
        <EarlyAccessBetaForm/>
    </Container>;
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 4rem;
  
  h2 {
    text-align: center;
  }
  
  .productImage {
    display: none;    
  }
  .blurb {
    padding: 0 2rem;  
  }
  
  @media (${Media.laptop}) {
    margin-top: 4rem;
    margin-bottom: 4rem;
    
    .row {
      max-width: 64rem;
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-column-gap: 4rem;    
      align-items: start;
      justify-content: center;    
    }
    
    .productImage {
      display: block;
      max-width: 20rem;  
      padding: 2rem 0 0 2rem;
    }
    
    .blurb {
      font-size: 0.9rem;  
    }
  }
  
  
  
`;
