import React from 'react';
import styled from 'styled-components';
import {IntrinsicElementProps} from "../../utils";
import {TwoToneText} from "../../components/TwoToneText";
import {HighlightTip} from "../../components/HighlightTip";
import {TryApp} from "../TryApp";
import {TextLogo} from "../../components/TextLogo";
import {Colors, Fonts, Media} from "../../constants";


export type RecogniseProps = {} & IntrinsicElementProps;

export function Recognise({...props}: RecogniseProps) {
    return <Container {...props}>
        <h2><TwoToneText w1={'Recognise'} w2={'This'}/>?</h2>
        <div className={'symptoms'}>
            <div className={'hours'}>Hours of life lost</div>
            <div className={'hunting'}>Constantly hunting for that perfect video</div>
            <div className={'hardons'}>No hardons with partners</div>
            <div className={'cantstop'}>Don't stop, even if you want to</div>
            <div className={'bored'}>Bored of porn, seen it all before</div>
            <div className={'intimacy'}>Struggling with relational intimacy</div>
            <div className={'lowenergy'}>Demotivated and low energy</div>
            <div className={'frustrated'}>Frustrated with life</div>
            <div className={'lonely'}>Lonely</div>
        </div>
        <HighlightTip className={'tip'} location={"top"} position={40} rotation={110}>
            Porn just ain't that good for you.
        </HighlightTip>
        <div className={'motivation'}>
            <div className={'questions'}>
                <div>Tried to quit but keep relapsing?</div>
                <div>Blockers and family filters not working?</div>
            </div>
            <TryApp header={<h3>You are not alone!<br /> <TextLogo/> is here for you... even if it is just an app.</h3>}/>
        </div>
    </Container>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4em;
  margin-bottom: 4em;
  padding-left: 2em;
  padding-right: 2em;
  
  h2 {
    margin-bottom: 1em;
    text-align: center;
  }
  
  .symptoms {
    display: grid;
    column-gap: 4em;
    row-gap: 2em;
    align-self: center;
    justify-self: center;
        
    grid-template-columns: 1fr;
    @media (${Media.tablet}) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
    
    &>* {
      width: 100%;
      max-width: 12rem;      
    }
  }
  
  .tip {
    margin-top: 2em;
    left: 15%;
  }
  
  .motivation {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    @media (${Media.tablet}) {
      flex-direction: row;
    }
        
    &>* {
      flex: 1 1 50%;
    }
    
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    margin-top: 2em;
    padding-top: 2em;
    
    .questions {
      position: relative;
                   
      &>* {
        margin-bottom: 2em;
        max-width: 20rem;
        margin-left: 2rem;
        @media (${Media.laptop}) {
          margin-left: 0;
        }
        &::before {
          content: '?';
          color: ${Colors.Yellow};        
          position: absolute; 
          left: -2rem;
          margin-top: 0.25rem;
          font-size: 2rem;
          font-family: ${Fonts.Inter};
          margin-left: 2rem;
      
          @media (${Media.laptop}) {
            margin-left: 0;
          }    
          
        }
      }
    }
    
  }

`;
