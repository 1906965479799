import * as React from "react"
import {ReactNode} from "react"
import {AppBar} from "../components/AppBar";
import {Footer} from "../components/Footer";
import {Media} from "../constants";
import styled from 'styled-components';
import {Root} from "./Root";
import {PageState} from "./PageState";
import { Helmet } from "react-helmet"
// @ts-ignore
import SocialImage from "../images/social.png";

export type LayoutProps = {
    className?: string;
    children: ReactNode;
    meta?: {
        title?: string;
        canonical?: string;
        description?: string;
    }
} & PageState;

export function Layout({children, className, meta, ...pageStateProps}: LayoutProps) {
    return (
        <Root className={className}>
            <PageState {...pageStateProps}>
                <img src={SocialImage} style={{width: 0, height: 0, opacity: 0}}/>
                <Helmet>
                    <title>{meta?.title || 'unprn'}</title>
                    <meta charSet="utf-8" />
                    <meta name="description" content={meta?.description || "Imagine an app that helped you do something other than porn right when you actually go to use porn. That’s exactly what unprn does."} />
                    <link rel="canonical" href={meta?.canonical || "https://unprn.com"} />
                </Helmet>
                <AppBar/>
                <Main className={'main'}>
                    {children}
                </Main>
                <Footer/>
            </PageState>
        </Root>
    )
}


const Main = styled.div`
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden ;
  
  @media (${Media.laptop}) {
      align-items: center;  
      justify-content: center;      
  }
`;



