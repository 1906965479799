import React from 'react';
import {PageProps} from "gatsby";
import {EmbedLayout} from "../../containers/EmbedLayout";
import {HSForm, HSFormTestId} from "../../components/HSForm";

type GetProFormDataType = [{name: string, value: string}] | {};

export type GetProProps = {} & PageProps;
export default function GetPro({...props}: GetProProps) {
    // TODO the getpro form needs text and styling!
    return <EmbedLayout {...props} meta={{title: 'Get Pro'}}>
        <div className={'p-m-3'}>
            <h4>Test form</h4>
            <HSForm<GetProFormDataType> formId={HSFormTestId} onSubmit={onSubmit}/>
            {/*<HSForm formId={'aeb04e14-6696-43ef-8895-1bc2b2a2dbc0'}/>*/}
        </div>
    </EmbedLayout>;
}

function onSubmit(data: GetProFormDataType) {
    if (Array.isArray(data)) {
        const email = data.find(entry => entry.name === 'email')?.value;
        if (email) {
            console.log('FlutterApp??', window.FlutterApp);
            window.FlutterApp?.postMessage(JSON.stringify({event: 'getProSignup', email: email}))
        }
    }
}
