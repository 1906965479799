import React from 'react';
import styled from 'styled-components';
import {TwoToneText} from "../components/TwoToneText";
import {HighlightTip} from "../components/HighlightTip";
import {HSForm} from "../components/HSForm";
import {H2} from "../components/NativeComponents";


export type ContactUsProps = {
    TitleComponent?: React.ComponentType<any>
};
export function ContactUs({TitleComponent= H2}: ContactUsProps) {
    return <Container>
        <div id={"contactus"}/>
        <TitleComponent><TwoToneText w1={'Contact'} w2={'Us'} reverse /></TitleComponent>
        <HighlightTip location='bottom' position={45} rotation={0} className={'tip'}>
            <div className={'tip-content'}>
                <div>Have questions or feedback?</div>
                <div>Write to us!</div>
            </div>
        </HighlightTip>
        <div className={'form'}>
            <HSForm formId={'fca20b0c-6d19-4b01-8413-a57742719358'}/>
            <p className={'direct'}>
                Or contact us directly at <a className='decorated' href={'mailto:hi@unprn.com'}>hi@unprn.com</a>
            </p>
        </div>
    </Container>;
}


const Container = styled.div`
  background: black;
  padding: 2em 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .tip {
    left: 12%;
  }
   
  .tip-content {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;    
  }
  
  .form {
    width: 100%;
    max-width: 40rem;    
  }
  
  .tryapp {
    margin-top: 4rem;
    padding-top: 2rem;
    margin-bottom: 4rem;
  
    border-top: 1px solid rgba(255,255,255,0.15);
    text-align: center;
    width: 100%;
    max-width: 30rem;
  }
`;
