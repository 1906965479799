import "primereact/resources/themes/saga-orange/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';
import * as React from "react"
import {Layout} from "../containers/Layout";
import styled from 'styled-components';
// @ts-ignore
import {PageProps} from "gatsby";
import AboutHero from "../sections/about/AboutHero";
import {ContactUs} from "../sections/ContactUs";
import {TryApp} from "../sections/TryApp";
import {TheTeam} from "../sections/about/TheTeam";
import {TheHistory} from "../sections/about/TheHistory";
import {TheProduct} from "../sections/about/TheProduct";


export default function About(props: PageProps) {
    return (
        <Layout {...props} meta={{title: 'About'}}>
            <div id={"about"}/>
            <AboutHero/>
            <TheHistory />
            <TheProduct />
            <TheTeam/>
            {/*<ContactUs/>*/}
            <TryAppSection />
        </Layout>
    )
}

const TryAppSection = styled(TryApp)`
  margin-top: 4rem;
  padding-top: 2rem;
  margin-bottom: 4rem;
  border-top: 1px solid rgba(255,255,255,0.15);
`;

const Blurbs = styled.div`
  width: 100%;
  max-width: 64rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;  
  align-items: start;  
`;
