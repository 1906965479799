import React, {ReactNode} from 'react';
import styled from 'styled-components';

import {TextLogo} from "../components/TextLogo";
import EarlyAccessBetaForm from "../components/EarlyAccessBetaForm";
import {IntrinsicElementProps} from "../utils";
import {Media} from "../constants";

export type TryAppProps = {
    header?: ReactNode;
} & IntrinsicElementProps

export function TryApp({header, id = 'tryapp', ...props}: TryAppProps) {
    return <Container id={id} {...props}>
        {header || <h2>Want to try out the <TextLogo/> app?</h2>}
        <EarlyAccessBetaForm/>
    </Container>
}

const Container = styled.div`
  text-align: center;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
    
  @media (${Media.laptop}) {
    max-width: 30rem;    
  }
`;
