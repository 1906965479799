import React from 'react';
import styled from 'styled-components';
import {IntrinsicElementProps} from "../../utils";
import {TwoToneText} from "../../components/TwoToneText";
import {HighlightScribble} from "../../components/HighlightScribble";
// @ts-ignore
import PicAlastair from '../../images/alastair2.svg';
// @ts-ignore
import PicGiles from '../../images/giles.svg';
// @ts-ignore
import PicLiviu from '../../images/liviu.svg';
// @ts-ignore
import PicTom from '../../images/tom.svg';
// @ts-ignore
import PicMiles from '../../images/miles.svg';
// @ts-ignore
import PicDave from '../../images/dave.svg';
import {TextLogo} from "../../components/TextLogo";
import {Fonts, Media, Palette} from "../../constants";
import {Link} from "../../components/Link";


export type TheTeamProps = {} & IntrinsicElementProps;

export function TheTeam({...props}: TheTeamProps) {
    return <Container {...props}>
        <h2><TwoToneText w1={'The'} w2={'Team'}/></h2>
        <Alastair>
            <img className={'profile'} src={PicAlastair}/>
            <div className={'name'}>Alastair Brayne</div>
            <div className={'subtext'}>
                <div className={'job mobile'}>
                    <div>Founder / Developer / Counsellor</div>
                </div>
                <div className={'job laptop'}>
                    <div>Founder</div>
                    <div>Developer</div>
                    <div>Counsellor</div>
                </div>
                <HighlightScribble className={'tip'}>aka "Chief User Advocate"</HighlightScribble>
            </div>
            <div className={'blurb1'}>Alastair has been developing software for over 15 years and is just completing his training as a psychotherapist. He is as old as one can be to have had the internet in the bedroom as a teenager. As a result, he has battled with porn as a high-functioning addict all his adult life - and suffered the impacts.</div>
            <div className={'blurb2'}><TextLogo/> is Alastair's soul-calling. His mission: to help his fellow addicts in those dark moments he knows so well - wishing something like this had been there for him.</div>
        </Alastair>
        <Team>
            {/*<Person name={'Giles Tongue'} position={'Commercial'} pic={PicGiles}/>*/}
            <Person name={'Dave Evans'} position={'Product Strategist'} pic={PicDave}/>
            <Person name={'Liviu Luca'} position={'Android Developer'} pic={PicLiviu}/>
            {/*<Person name={'Tom Rogers'} position={'Finance'} pic={PicTom}/>*/}
            <Person name={'Miles Chesney'} position={'Business Advice'} pic={PicMiles}/>
        </Team>
        <WorkWithUs/>
    </Container>;
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    text-align: center;
  }
  @media (${Media.laptop}) {
    margin-top: 4rem;
    //margin-bottom: 4rem;
  }
`;


const Alastair = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 2rem 0 2rem;
  text-align: center;
  .profile {
    padding-bottom: 2rem;    
  }
  .name {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .job {
    color: ${Palette.Yellow.hash};
  }
  .tip {
    margin: 1rem auto;
    height: 6rem;
    .HighlightScribble-content {
      font-size: 1.2rem;
    }
  }
  .blurb1, .blurb2 {
    margin-bottom: 1rem;
  }
  

  @media (${Media.laptop}) {
    margin-top: 4rem;
    margin-bottom: 4rem;
    max-width: 56rem;
    display: grid;
    grid-template-columns: 7fr 5fr;
    grid-template-rows: auto auto;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;

    grid-template-areas:
      "title pic"
      "subtext pic"
      "blurb1 blurb2"
    ;

    .name {
      grid-area: title;
      font-size: 52px;
      text-align: center;
      margin-top: 2rem;
    }
    .subtext {
      grid-area: subtext;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .job {
        font-family: ${Fonts.IndieFlower};
        font-size: 36px;
        color: ${Palette.Yellow.hash};
        display: flex;
        flex-direction: row;

        &>* {
        margin-left: 1rem;
        }
      }
      .tip {
        grid-area: subtext;
        margin-top: 1rem;
      }
    }
    .profile {
      grid-area: pic;
      justify-self: center;
      align-self: center;
      max-width: 100%;
      max-height: 100%;
    }
    .blurb1 {
      text-align: left; 
      grid-area: blurb1;
    }
    .blurb2 {
      text-align: left;
      grid-area: blurb2;
    }
  }
`;


const Team = styled.div`
  .person {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem 0;
    
    img {
      max-width: 66%;
    }
    .name {
      font-size: 1.6rem;
      margin: 1rem 0 0.5rem 0;
    }
  }
  
  @media (${Media.laptop}) {
    display: flex;
    flex-direction: row;
  }
`;

type PersonProps = {
    name: string;
    position: string;
    pic: string;
    blurb?: string;
} & IntrinsicElementProps;

function Person({name, position, pic, blurb, ...props}: PersonProps) {
    return <div {...props} className={'person'}><img src={pic}/>
        <div className={'name'}>{name}</div>
        <div className={'position'}>{position}</div>
        {blurb ? <div className={'blurb'}>{blurb}</div> : <React.Fragment/>}</div>
}


type WorkWithUsProps = {} & IntrinsicElementProps;

function WorkWithUs({...props}: WorkWithUsProps) {
    return <WorkWithUsContainer {...props}>
        <h2><TwoToneText w1={'Work'} w2={'With Us'} reverse/></h2>
        <div>Do you think <TextLogo/> is a seriously needed product too?</div>
        <div>Want to help take it to the moon? <Link to={'#contactus'}>Get in touch</Link>.</div>
    </WorkWithUsContainer>;
}

const WorkWithUsContainer = styled.div`
  margin: 6rem 0;
  &>div {
    text-align: center;
    margin: 2rem;
  }
  a {
    color: ${Palette.Yellow.hash};
  }
`;
