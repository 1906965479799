import * as React from 'react';
import styled from 'styled-components';
import {Fonts, Media, Palette} from '../constants';
import {HSForm} from "./HSForm";
import {ReactNode} from "react";

export type EarlyAccessBetaFormProps = {
    className?: string;
    title?: ReactNode;
};
export default function EarlyAccessBetaForm({className, title}: EarlyAccessBetaFormProps) {
    // useEffect(() => {
    //     // @ts-ignore
    //     if (window.hbspt) {
    //         // @ts-ignore
    //         window.hbspt.forms.create({
    //             region: "eu1",
    //             portalId: "25011193",
    //             formId: "e15fc64e-59c1-4bf1-a88e-6f6ff747581d",
    //             target: '#hubspotForm'
    //         })
    //     }
    // }, []);

    return (
        <Container className={className}>
            <Title>{title || 'Sign up to the Early Access Beta'}</Title>
            <Footer>
                <HSForm formId='e15fc64e-59c1-4bf1-a88e-6f6ff747581d'/>
            </Footer>
        </Container>
    )
}

const Container = styled.div`
    display: flex ;
    flex-direction: column ;
    margin-top: 2.5em;
    @media (${Media.mobile}) {
      margin-top: 3.4em;
    }
`;
const Title = styled.div`
    font-family: ${Fonts.Poppins};
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    letter-spacing: -0.01em;
    font-feature-settings: 'calt' off;
    color: ${Palette.Default.color};
    @media (${Media.mobile}) {
      text-align: center ;
    }
`;
const Footer = styled.div`
    display: flex ;
    flex-direction: row ;
    margin-top: 0.5em;    
    @media (${Media.mobile}) {
      flex-direction: column ;
      margin-top: 2.5em;
    }
`;
