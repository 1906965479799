import React from 'react';
import styled from 'styled-components';
import {Layout} from "../containers/Layout";
import {ContactUs} from "../sections/ContactUs";
import {PageProps} from "gatsby";
import {H1} from "../components/NativeComponents";
import {TryApp} from "../sections/TryApp";

export type ContactProps = {} & PageProps;
export default function Contact({...props}: ContactProps) {
    return (
        <Layout {...props} meta={{title: 'Contact Us'}}>
            <div id={"contact"}/>
            <ContactUs TitleComponent={H1}/>
            <TryAppSection />
        </Layout>
    )
}

const TryAppSection = styled(TryApp)`
  margin-top: 4rem;
  padding-top: 2rem;
  margin-bottom: 4rem;
  border-top: 1px solid rgba(255,255,255,0.15);
`;

