// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blocked-tsx": () => import("./../../../src/pages/blocked.tsx" /* webpackChunkName: "component---src-pages-blocked-tsx" */),
  "component---src-pages-contact-success-tsx": () => import("./../../../src/pages/ContactSuccess.tsx" /* webpackChunkName: "component---src-pages-contact-success-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-embed-bugreport-tsx": () => import("./../../../src/pages/embed/bugreport.tsx" /* webpackChunkName: "component---src-pages-embed-bugreport-tsx" */),
  "component---src-pages-embed-contact-tsx": () => import("./../../../src/pages/embed/contact.tsx" /* webpackChunkName: "component---src-pages-embed-contact-tsx" */),
  "component---src-pages-embed-getpro-tsx": () => import("./../../../src/pages/embed/getpro.tsx" /* webpackChunkName: "component---src-pages-embed-getpro-tsx" */),
  "component---src-pages-embed-privacy-tsx": () => import("./../../../src/pages/embed/privacy.tsx" /* webpackChunkName: "component---src-pages-embed-privacy-tsx" */),
  "component---src-pages-embed-terms-tsx": () => import("./../../../src/pages/embed/terms.tsx" /* webpackChunkName: "component---src-pages-embed-terms-tsx" */),
  "component---src-pages-forms-bugreport-tsx": () => import("./../../../src/pages/forms/bugreport.tsx" /* webpackChunkName: "component---src-pages-forms-bugreport-tsx" */),
  "component---src-pages-forms-contact-tsx": () => import("./../../../src/pages/forms/contact.tsx" /* webpackChunkName: "component---src-pages-forms-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

